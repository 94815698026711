import { Component, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { AmsSidebarComponent } from "./sidebar.component";

@Component({
    selector: 'ams-layout',
    templateUrl: './layout.component.html'
})
export class AmsLayoutComponent /*extends AppLayoutComponent*/ {

  @ViewChild(AmsSidebarComponent) appSidebar!: AmsSidebarComponent;

  constructor(layoutService: LayoutService) {
    layoutService.state.sidebarMenuActive = true;

    layoutService.config.secondLogoText = 'Account Management';
    layoutService.config.secondLogoIcon = 'pi-users';
    layoutService.config.secondLogoUrl = '/ams';

    layoutService.config.dashboardBackground = false;
  }

}
