import { Component, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { GpsSidebarComponent } from "./gps.sidebar.component";

@Component({
    selector: 'gps-layout',
    templateUrl: './gps.layout.component.html'
})
export class GpsLayoutComponent /*extends AppLayoutComponent*/ {

  @ViewChild(GpsSidebarComponent) appSidebar!: GpsSidebarComponent;

  constructor(layoutService: LayoutService) {
    layoutService.state.sidebarMenuActive = true;

    layoutService.config.secondLogoText = 'GPS';
    layoutService.config.secondLogoIcon = 'pi-id-card';
    layoutService.config.secondLogoUrl = '/gps';
  }

}
