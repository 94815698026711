import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';

import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home.component';
import { GpsLoginComponent } from './components/login.component';
import { CardModule } from 'primeng/card';
import { GpsLogoutComponent } from './components/logout.component';
import { GpsSharedModule } from '../../gps-shared.module';

@NgModule({
  declarations: [
    HomeComponent,
    GpsLoginComponent,
    GpsLogoutComponent
  ],
  imports: [
    GpsSharedModule,
    CardModule,
    RouterModule,
    CommonModule,
    ButtonModule,
    RouterModule
  ]
})
export class HomeModule {}

