import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from './features/home/components/home.component';
import { GpsGuard } from "./services/gps-auth.service";
import { GpsLayoutLiteComponent } from "./features/layout/components/gps.layout-lite.component";
import { GpsLoginComponent } from "./features/home/components/login.component";
import { GpsLogoutComponent } from "./features/home/components/logout.component";
import { GpsLayoutComponent } from "./features/layout/components/gps.layout.component";

const routes: Routes = [
  {
    path: '', component: HomeComponent, pathMatch: 'full'
  },
  {
    path: 'gps', component: GpsLayoutComponent,
    canActivate: [GpsGuard],
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'iam', loadChildren: () => import('./features/iam/iam.module').then(m => m.IamModule) },
    //  { path: 'project-spokes', loadChildren: () => import('./features/project-spokes/project-spokes.module').then(m => m.ProjectSpokesModule) },
    ]
  },
  //{
  //  path: 'gps/network-requests',
  //  canActivate: [GpsGuard],
  //  loadChildren: () => import('./features/network-requests/network-request.module').then(m => m.NetworkRequestModule)
  //},
  {
    path: 'gps/login', component: GpsLayoutLiteComponent,
    children: [
      { path: '', component: GpsLoginComponent },
    ]
  },
  {
    path: 'gps/logout', component: GpsLayoutLiteComponent,
    children: [
      { path: '', component: GpsLogoutComponent },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class GpsRoutingModule {}
