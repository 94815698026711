import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { APPLICATION_TYPE } from '../../main';
import { ApplicationType } from '../shared/models/application-type';
import { LayoutService } from '../shared/services/layout.service';
import { HomeModule } from './features/home/home.module';
import { GpsLayoutModule } from './features/layout/gps.layout.module';
import { GpsRoutingModule } from './gps-routing.module';
import { GpsAuthService, GpsInterceptor } from './services/gps-auth.service';
import { GpsCommunicationService } from './services/gps-communication.service';
import { IamModule } from './features/iam/iam.module';


@NgModule({
  imports: [
    ButtonModule,
    MenubarModule,
    ConfirmDialogModule,
    ToastModule,

    GpsLayoutModule,
    HomeModule,

    IamModule,

    GpsRoutingModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),

    //GpsStore,
    GpsAuthService,
    GpsCommunicationService,

    { provide: APPLICATION_TYPE, useValue: ApplicationType.Gps },

    { provide: HTTP_INTERCEPTORS, useClass: GpsInterceptor, deps: [GpsAuthService, LayoutService], multi: true },
  ],
  //exports: [GpsPermissionDirective],
  //bootstrap: []
})
export class GpsModule {}
