import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ams-menu',
    templateUrl: './menu.component.html'
})
export class AmsMenuComponent implements OnInit {

  items: any[] = [];

    allItems = [
        {
            label: 'Subscriptions',
            icon: 'pi pi-fw pi-briefcase',
            items: [{
                label: 'Order New Sandbox Subscription',
                icon: 'pi pi-fw pi-plus',
                //permission: 'ProjectGetList'
            }, {
                label: 'Order New Dev,QA and Prod Subscriptions',
                icon: 'pi pi-fw pi-plus',
                //routerLink: '/gps/iam/aad-role-assign',
                //permission: 'ProjectGetList'
            }]
        },
        {
            label: 'Consumption Management',
            icon: 'pi pi-fw pi-gauge',
        },
        {
            label: 'Contract Management',
            icon: 'pi pi-fw pi-address-book',
        },
        {
            label: 'User Management',
            icon: 'pi pi-fw pi-users',
        }
    ];

  constructor(
  ) { }

  ngOnInit() {
    this.items = this.allItems;
  }
}
