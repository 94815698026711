import { Component, ElementRef } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
    selector: 'gps-sidebar',
    templateUrl: './gps.sidebar.component.html'
})
export class GpsSidebarComponent {
    constructor(public layoutService: LayoutService, public el: ElementRef) { }
}

