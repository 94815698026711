import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'tms-menu',
    templateUrl: './menu.component.html'
})
export class TmsMenuComponent implements OnInit {

  items: any[] = [];

  allItems = [
    {
      label: 'Policies Management',
      icon: 'pi pi-fw pi-shield',
    //  items: [{
    //    label: 'Assign AAD Role',
    //    icon: 'pi pi-fw pi-address-book',
    //    routerLink: '/gps/iam/aad-role-assign',
    //    //permission: 'ProjectGetList'
    //  }]
    },
    {
      label: 'Management Groups Management',
      icon: 'pi pi-fw pi-receipt',
    }
  ];

  constructor(
  ) { }

  ngOnInit() {
    this.items = this.allItems;
  }
}
