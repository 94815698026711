import { Component, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { TmsSidebarComponent } from "./sidebar.component";

@Component({
    selector: 'tms-layout',
    templateUrl: './layout.component.html'
})
export class TmsLayoutComponent /*extends AppLayoutComponent*/ {

  @ViewChild(TmsSidebarComponent) appSidebar!: TmsSidebarComponent;

  constructor(layoutService: LayoutService) {
    layoutService.state.sidebarMenuActive = true;

    layoutService.config.secondLogoText = 'Tenant Management';
    layoutService.config.secondLogoIcon = 'pi-warehouse';
    layoutService.config.secondLogoUrl = '/tms';

    layoutService.config.dashboardBackground = false;
  }

}
