import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';

import { CommonModule } from '@angular/common';
import { AppLayoutModule } from '../../../portal/features/layout/app.layout.module';
import { GpsLayoutLiteComponent } from './components/gps.layout-lite.component';
import { GpsLayoutComponent } from './components/gps.layout.component';
import { GpsMenuComponent } from './components/gps.menu.component';
import { GpsSidebarComponent } from './components/gps.sidebar.component';
import { GpsProgressLayoutComponent } from './components/progress.layout.component';
import { GpsSharedModule } from '../../gps-shared.module';

@NgModule({
  declarations: [
    GpsMenuComponent,
    GpsSidebarComponent,
    GpsLayoutComponent,
    GpsLayoutLiteComponent,
    GpsProgressLayoutComponent
  ],
  imports: [
    CommonModule,
    AppLayoutModule,
    ButtonModule,
    MenuModule, 
    SidebarModule,
    RippleModule,
    RouterModule,
    ProgressBarModule,
    GpsSharedModule
  ],
  exports: [GpsLayoutComponent]
})
export class GpsLayoutModule { }
