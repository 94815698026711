import { Component, OnInit } from '@angular/core';
import { GpsCommunicationService } from '../../../services/gps-communication.service';

@Component({
  selector: 'gps-logout',
  templateUrl: './logout.component.html',
})
export class GpsLogoutComponent implements OnInit {

  constructor(
    private communication: GpsCommunicationService,
    //private route: ActivatedRoute,
    //private portalCommunication: PortalCommunicationService
  ) {

    //const redirectTo = this.route.snapshot.queryParams['redirect'] ?? '/';

  }

  async ngOnInit() {
    //this.gpsAuth.logout()
    this.communication.userLogout$.next();
  }


}
