import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';

import { CommonModule } from '@angular/common';
import { AppLayoutModule } from '../../../portal/features/layout/app.layout.module';
import { IamLayoutComponent } from './components/layout.component';
import { IamMenuComponent } from './components/menu.component';
import { IamSidebarComponent } from './components/sidebar.component';

@NgModule({
  declarations: [
    IamMenuComponent,
    IamSidebarComponent,
    IamLayoutComponent
  ],
  imports: [
    CommonModule,
    AppLayoutModule,
    ButtonModule,
    MenuModule, 
    SidebarModule,
    RippleModule,
    RouterModule,
    ProgressBarModule
  ],
  exports: [IamLayoutComponent]
})
export class IamLayoutModule { }
