import { Component, OnInit, ViewChild } from '@angular/core';
import { ProgressBar } from 'primeng/progressbar';
import { Subject, takeUntil } from 'rxjs';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
  selector: 'gps-progress-layout',
  template: `
    @if(showProgress){
      <p-progressBar #progressbar mode="indeterminate" [style]="{ height: '4px', 'margin-bottom':'-4px'}" [color]="'var(--primary-200)'"></p-progressBar>
    }`
})
export class GpsProgressLayoutComponent implements OnInit {

  showProgress = false;
  private destroyed$ = new Subject<void>();

  @ViewChild(ProgressBar) progressbar!: ProgressBar;

  constructor(
    public layoutService: LayoutService
  ) {
  }

  ngOnInit() {
    this.layoutService.progressBarRequests$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((n:number) => {
        this.showProgress = n > 0;
        console.log("ProgressLayout Gps:", n)
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
