import { Component, OnInit } from '@angular/core';
//import { UserDetailsService } from '../../shared/api/user-details.service';
import { GpsStore } from '../../../services/gps.store';

@Component({
    selector: 'gps-menu',
    templateUrl: './gps.menu.component.html'
})
export class GpsMenuComponent implements OnInit {

  items: any[] = [];

  allItems = [
    {
      label: 'Identity Access Management',
      icon: 'pi pi-fw pi-id-card',
      //permission: 'ProjectGetList,UserProjectMappingGetList',
      items: [{
        label: 'Assign AAD Role',
        icon: 'pi pi-fw pi-address-book',
        routerLink: '/gps/iam/aad-role-assign',
        //permission: 'ProjectGetList'
      }]
    }
  ];

  constructor(
    //private userService: UserDetailsService,
    private store: GpsStore
  ) { }

  ngOnInit() {

    this.items = this.allItems;

    //this.store.gpsUser$
    //  .subscribe(user => {
    //    if (!user) {
    //      this.items = [];
    //    } else {
    //      //filters items based on permissions
    //      this.items = this.allItems
    //        .filter(i => !i.permission || this.userService.hasPermission(i.permission, user?.roles))
    //        .map(i => {
    //          i.items = i.items.filter(s => !s.permission || this.userService.hasPermission(s.permission, user?.roles));
    //          return i;
    //        });
    //    }
    //  });

  }
}
