
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { GpsStore } from '../../../services/gps.store';
import { PermissionDictionary, TenantDto } from './configuration.model';

export const GpsConfigurationApiUrl = {
  ConfigurationTenants: () => "/api/configuration/tenants",
  ConfigurationUserRoles: () => "/api/configuration/roles",
  ConfigurationPermissions: () => "/api/configuration/permissions",
  ConfigurationValidators: () => "/api/configuration/validators",
  ConfigurationVariableList: () => "/api/configuration/variables",
}

@Injectable({providedIn: 'root'})
export class ConfigurationService extends BaseApiService{

  constructor(store: GpsStore) {
    super(store.apiUrl);
  }

  //#region Configuration

  tenants(): Observable<TenantDto[]> {
    return super.get<TenantDto[]>(GpsConfigurationApiUrl.ConfigurationTenants());
  }

  roles(): Observable<string[]> {
    return super.get<string[]>(GpsConfigurationApiUrl.ConfigurationUserRoles());
  }

  validators(endpoint: string): Observable<ValidationRule[]> {
    return super.get<ValidationRule[]>(GpsConfigurationApiUrl.ConfigurationValidators(), { endpoint });
  }

  permissions(): Observable<PermissionDictionary> {
    return super.get<PermissionDictionary>(GpsConfigurationApiUrl.ConfigurationPermissions());
  }

  //#endregion
}
