import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { APPLICATION_TYPE } from '../../main';
import { ApplicationType } from '../shared/models/application-type';
import { HomeModule } from './features/home/home.module';
import { TmsLayoutModule } from './features/layout/tms.layout.module';
import { TmsRoutingModule } from './tms-routing.module';


@NgModule({
  imports: [
    ButtonModule,
    MenubarModule,
    ConfirmDialogModule,
    ToastModule,

    TmsLayoutModule,
    HomeModule,

    TmsRoutingModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),

    { provide: APPLICATION_TYPE, useValue: ApplicationType.Gps },
  ]
})
export class TmsModule {}
