<h1 class="text-center mt-5">GPS: Grant Permissions Service</h1>

<div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
  <div class="grid justify-content-center">

    <a class="col-12 md:col-6 lg:col-4" routerLink="./iam/aad-role-assign">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">IAM</span>
            <div class="text-900 font-medium text-xl">Identity Access Management</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-address-book text-blue-500 text-xl"></i>
          </div>
        </div>
        <!--<span class="text-green-500 font-medium">%52+ </span>
      <span class="text-500">since last week</span>-->
      </div>
    </a>    

  </div>
</div>
